import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://pypi.org/project/bottle-cors-plugin/"
      }}>{`bottle-cors-pluging`}</a></h1>
    <p>{`This does not handle the cors correctly but gets the job done for some local testing of a bottle app, I just use Bottle.py for prototyping,
and this library helped me with the cors problem when trying to request the API with the frontend.`}</p>
    <p>{`This is my first library to have an issue on Github and I'm pretty happy about it, isn't perfect by any means but is one of the many steps,
that I have taken to become a better programmer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      